/* Reset some default styling */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/* Set up a simple global style */

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f8f9fa;
}